@import "./pages/index";

/* Global Vars */
:root {
  --primary-color: #0021b4;
  --accent-color: #00fddb;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Manrope", system-ui;
  font-optical-sizing: auto;
}

.noselect{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}