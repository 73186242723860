.ComingSoon {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    flex-direction: column;
    z-index: 1;

    &__background{
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        .noise {
            height: 100%;
            background: linear-gradient(45deg, rgb(12, 12, 12), transparent), url("../../assets/noise.svg");
            filter: contrast(170%) brightness(1000%);
        }
    
        /* Chrome-specific */
        @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
            .noise {
                filter: contrast(290%) brightness(1000%);
            }
        }
    
        .overlay {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            background-color: var(--primary-color);
        }
    }

    &__languageChanger{
        position: absolute;
        top: 50px;
        right: 90px;
        background-color: color-mix(in srgb, white 40%, transparent);
        border-radius: 25px;
        border: 2px solid white;
        display: flex;
        flex-direction: row;
        gap: 5px;
        padding: 4px;
        .option{
            color: white;
            font-size: 15px;
            line-height: 15px;
            padding: 8px 18px;
            font-weight: 700;
            border-radius: 25px;
            cursor: pointer;
            &.active{
                color: black;
                background-color: white;
            }
        }
    }

    &__leftSection{
        display: flex;
        align-items: flex-start;
        padding-left: 120px;
        flex-direction: column;
        z-index: 2;
        gap: 30px;
        color: white;
        h1,h2,h3,h4{
            margin: 0;
        }
        &__logo{
            position: absolute;
            display: flex;
            flex-direction: row;
            gap: 5px;
            top: 50px;
            font-size: 30px;
            font-weight: 800;
            color: var(--accent-color);
            img{
                height: 40px;
                width: 40px;
            }
        }
        &__title{
            font-size: 150px;
            font-weight: 600;
            line-height: 130px;
        }
        &__subtitle{
            font-size: 28px;
            font-weight: 400;
            .highContrast{
                font-weight: 800;
                font-size: 31px;
                background: -webkit-linear-gradient(180deg, var(--accent-color), white);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                white-space: nowrap;
            }
        }
        &__mailSection{
            margin-top: 120px;
            display: flex;
            flex-direction: row;
            height: 46px;
            border-radius: 25px;
            background-color: color-mix(in srgb, white 40%, transparent);
            border: 2px solid white;
            position: relative;
            align-items: center;
            width: 440px;
            &__mailSubtitle{
                position: absolute;
                top: calc(100% + 20px);
                font-size: 14px;
                line-height: 15px;
                font-weight: 400;
                width: 500px;
                .logoColor{
                    font-weight: 600;
                    color: var(--accent-color);
                }
            }
            label{
                position: absolute;
                top: -25px;
                font-size: 13px;
            }
            input{
                background-color: transparent;
                height: 100%;
                border-radius: 25px;
                color: white;
                border: unset;
                flex: 1;
                outline: none;
                padding: 0px 15px;
                font-size: 20px;
                margin-right: 5px;
                &:focus{
                    outline: none;
                }
            }
            button{
                margin-right: 3px;
                height: 40px;
                background: white;
                outline: none;
                border: unset;
                padding: 0px 15px;
                border-radius: 25px;
                font-weight: 700;
                font-family: "Manrope", system-ui;
                &:disabled{
                    opacity: 0.7;
                    color: black;
                }
                &:not(:disabled){
                    cursor: pointer;
                    &:hover,&:active{
                        background-color: var(--accent-color);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 799px) {
    .ComingSoon{
        &__leftSection{
            padding-left: 0;
            justify-content: center;
            padding: 30px;
            &__title{
                font-size: 80px;
                line-height: 80px;
            }
            &__subtitle{
                font-size: 14px;
                line-height: 20px;
                .highContrast{
                    font-size: 14px;
                    line-height: 20px; 
                }
            }
            &__mailSection{
                width: 100%;
                input{
                    width: 100%;
                }
                &__mailSubtitle{
                    max-width: 100%;
                }
            }
        }
        &__languageChanger{
            right: 30px;
        }
    }
}